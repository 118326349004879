<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('code', ['publicInfo']),
  },
  data() {
    return {
      cookiesData: null,
    };
  },
  render() {
    return this.$scopedSlots.default({
      config: this.cookiesData,
    });
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.cookiesData = {
        version: 2,
        termsLink: 'https://www.rfider.com/terms-of-use',
        policyLink: 'https://tradewindow.io/legals/privacy-policy',
        cookieLink: 'https://tradewindow.io/legals/cookie-policy',
        urlPrivacyPolicy:
          this.publicInfo &&
          this.publicInfo.info &&
          this.publicInfo.info.urlPrivacyPolicy
            ? this.publicInfo.info.urlPrivacyPolicy
            : '',
        entityName: this.publicInfo ? this.publicInfo.organizationName : '',
      };
    },
  },
};
</script>
